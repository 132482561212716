import React from 'react';
import { useApolloClient } from '@apollo/client';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { trackSocialButtonClicked } from 'src/common/tracking/trackingUtils';
import { OAuthDialog } from 'src/components/Dialog/OAuthDialog';
import { SignInSource } from 'src/components/LoginModal/ExternalSignInButtons';
import { IDENTITY_TYPES } from 'src/modules/Settings/Constants';

import { TRACKING_CLASSNAMES } from '../common/constants';
import { loginWithLinkedIn } from '../modules/Session/Actions';
import { LinkedinButton } from '../styles/components/buttons';

const Icon = styled.i`
  background-image: url('/images/onboarding/linkedin-white.png');
`;

type LoginWithLinkedinButtonProps = {
  className?: string;
  gtmData: { login: string };
  signInSource: SignInSource;
};

const LoginWithLinkedinButton = ({
  className,
  gtmData,
  signInSource,
}: LoginWithLinkedinButtonProps) => {
  const dispatch = useDispatch();
  const router = useRouter();
  const apolloClient = useApolloClient();

  return (
    <LinkedinButton
      className={classNames(TRACKING_CLASSNAMES.linkedinLoginButton, className)}
      id={`${IDENTITY_TYPES.LINKEDIN}-oauth-button`}
      onClick={() =>
        trackSocialButtonClicked({
          type: 'linkedIn',
          page: router.asPath.includes('signup') ? 'signup' : 'login',
        })
      }
    >
      <Icon />
      <OAuthDialog
        platform={IDENTITY_TYPES.LINKEDIN}
        authAction={(code, csrfToken) => {
          dispatch(
            loginWithLinkedIn(
              code,
              csrfToken,
              gtmData,
              signInSource,
              apolloClient
            )
          );
        }}
      />
    </LinkedinButton>
  );
};

export default LoginWithLinkedinButton;
