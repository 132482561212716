/*
 * This Wrapper component serves as a workaround for linking login and sign ups with linkedin & facebook
 * Upon clicking the login or sign up button, a new window will open with the path to
 * /oauth2/facebook or /oauth2/linkedin which will render this component, after which the user is
 * redirected to the corresponding external login dialog.
 *
 * By opening this new window with the same origin,
 * it serves as a workaround for the cross origin policy in Edge.
 */

import React from 'react';
import Router from 'next/router';

import { isXssPayload } from 'src/common/utils/security';

export const senderCode = 'SameOriginAuthDialogOpener';

const SameOriginAuthDialogOpener = () => {
  React.useEffect(() => {
    const params = Router.query;
    if (params) {
      if (params.error) {
        const errorMessage =
          params.error_description || 'Login failed. Please try again.';
        if (window.opener) {
          window.opener.postMessage(
            {
              error: params.error,
              state: params.state,
              errorMessage,
              from: senderCode,
            },
            window.location.origin
          );
        }
        if (
          params.error === 'user_cancelled_login' ||
          params.error === 'user_cancelled_authorize'
        ) {
          window.close();
        }
      }
      if (params.code) {
        if (window.opener) {
          window.opener.postMessage(
            { code: params.code, state: params.state, from: senderCode },
            window.location.origin
          );
        }
      }
      if (params.redirect_url && !isXssPayload(params.redirect_url)) {
        window.location.href = params.redirect_url;
      }
    }
  }, []);

  return null;
};

export default SameOriginAuthDialogOpener;
