import { Greyscale, PrimaryColor } from 'glints-aries';
import styled from 'styled-components';

const DefaultBtn = styled.button`
  position: relative;
  display: flex;
  border: none;
  cursor: pointer;
`;

export const GhostBtn = styled(DefaultBtn)`
  align-self: center;
  border: 2px solid ${PrimaryColor.glintsred};
`;

export const OverlappingBtn = styled.div`
  position: relative;
  background-color: ${PrimaryColor.glintsred};
  margin-left: 1em;
  outline: none;

  &:after {
    content: '';
    width: 100%;
    height: 50px;
    background-color: ${PrimaryColor.glintsyellow};
    position: absolute;
    z-index: -1;
    left: -0.6em;
    top: 0.6em;
    transition: background-color 0.5s;
  }

  &:hover {
    &:after {
      content: '';
      width: 100%;
      height: 50px;
      background-color: ${Greyscale.black};
      position: absolute;
      z-index: -1;
      left: -0.6em;
      top: 0.6em;
      transition: background-color 0.5s;
    }
  }
`;

// Social Media Button
const OnboardingButton = styled(DefaultBtn)`
  padding: 1em;
  color: ${Greyscale.white};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  p {
    font-size: 15px;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 0.6px;
  }
  i {
    height: 40px;
    width: 40px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    display: block;
  }
`;

export const FacebookButton = styled(OnboardingButton)`
  font-size: 12px;
  background: #1877f2;
`;

export const LinkedinButton = styled(OnboardingButton)`
  font-size: 11px;
  background: #0a66c2;
`;
