import { useSelector } from 'react-redux';

import isServer from 'src/common/isServer';
import { getConfig } from 'src/modules/ClientConfig';
import { IDENTITY_TYPES } from 'src/modules/Settings/Constants';

export const useGetOauthDialogUrl = (
  platform: IDENTITY_TYPES,
  csrfToken: string
) => {
  const config = useSelector(getConfig);

  if (isServer) {
    return null;
  }

  switch (platform) {
    case IDENTITY_TYPES.LINKEDIN: {
      const linkedInAppId = config.LINKEDIN_APPID;
      const redirectUri = `${
        document.getElementsByTagName('base')[0].href
      }oauth2/linkedin`;
      const dialogUrlTemp =
        'https://www.linkedin.com/oauth/v2/authorization?' +
        `client_id=${linkedInAppId}&` +
        `redirect_uri=${encodeURIComponent(redirectUri)}&` +
        `scope=${encodeURIComponent('r_emailaddress r_liteprofile')}&` +
        `response_type=code&state=${csrfToken}`;
      return `${
        window.location.origin
      }/oauth2/linkedin?redirect_url=${encodeURIComponent(dialogUrlTemp)}`;
    }

    case IDENTITY_TYPES.FACEBOOK: {
      const facebookAppId = config.FACEBOOK_APPID;
      const redirectUri = `${
        document.getElementsByTagName('base')[0].href
      }oauth2/facebook`;
      const dialogUrlTemp =
        'https://www.facebook.com/dialog/oauth?' +
        `client_id=${facebookAppId}&` +
        `redirect_uri=${encodeURIComponent(redirectUri)}&` +
        `response_type=code&scope=email&display=popup&state=${csrfToken}`;
      return `${
        window.location.origin
      }/oauth2/facebook?redirect_url=${encodeURIComponent(dialogUrlTemp)}`;
    }
  }
};
