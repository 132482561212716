import React, { useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';

import useMount from 'src/common/hooks/useMount';
import { senderCode } from 'src/components/Dialog/SameOriginAuthDialogOpener';
import { useGetOauthDialogUrl } from 'src/components/Dialog/utils';
import { IDENTITY_TYPES } from 'src/modules/Settings/Constants';

interface Props {
  authAction: (code: string, csrfToken: string) => void;
  platform: IDENTITY_TYPES;
}

export const OAuthDialog: React.FunctionComponent<
  React.PropsWithChildren<Props>
> = ({ authAction, platform }) => {
  const popup = useRef(null);
  const csrfToken = uuidv4();

  const dialogURL = useGetOauthDialogUrl(platform, csrfToken);

  useMount(() => {
    // we use .onclick instead of component onClick to bypass browser popup blocker, Note: .onclick is regarded as user event. ref: https://javascript.info/popup-windows
    // TODO: change document selector by ref when https://github.com/glints-dev/glints-aries/issues/575 is resolved
    document.getElementById(`${platform}-oauth-button`).onclick = () =>
      openPopup();
  });

  const openPopup = () => {
    if (!popup.current || (popup.current && popup.current.closed)) {
      popup.current = window.open(dialogURL, '_blank', 'width=527,height=582');

      // check if window has successfuly opened, window.open() return null when window does not successfully open the target url
      if (!popup.current) {
        return;
      }
      const timer = setInterval(() => {
        if (popup.current.closed) {
          closePopup();
          clearInterval(timer);
        }
      }, 500);

      window.addEventListener('message', receiveMessage, false);
    }
  };

  const closePopup = () => {
    if (popup.current) {
      popup.current.close();
    }
  };

  const receiveMessage = (event: any) => {
    if (
      event.origin === window.location.origin &&
      event.data.from === senderCode
    ) {
      if (event.data.state !== csrfToken) {
        closePopup();
        return;
      }
      if (event.data.errorMessage) {
        closePopup();
      } else if (event.data.code) {
        authAction(event.data.code, csrfToken);
        closePopup();
      }
    }
  };
  return <></>;
};
